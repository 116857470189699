import { API_BASE_URL } from '@/config'
import { REACT_APP_CHAIN_ID } from '@/Cover-Portal/constants'

export const endPoints = {
  HEALTH_CHECK: `${API_BASE_URL}health-check`,
  USER_LOGIN: `${API_BASE_URL}authentication/login`,
  USER_REGISTER: `${API_BASE_URL}user/register`,
  ENTITY: `${API_BASE_URL}entity/query`,
  EVENT: `${API_BASE_URL}event/query`,
  PAST_EVENT: `${API_BASE_URL}event/capture-past-events`,
}

export const COVER_GRAPH_ENDPOINTS =
  REACT_APP_CHAIN_ID === '1'
    ? 'https://api.studio.thegraph.com/query/81903/unore-cover-portal-subgraph/version/latest'
    : 'https://api.studio.thegraph.com/query/78945/uno-sales-sepolia-eth/version/latest'

export const GRAPH_ENDPOINTS = {
  0: 'https://api.studio.thegraph.com/query/81903/uno-re-ssip-usdc-bsc/version/latest',
  1: 'https://api.studio.thegraph.com/query/81903/uno-re-ssip-uno-bsc/version/latest',
  2: 'https://api.studio.thegraph.com/query/81903/uno-re-ssip-usdt-eth/version/latest',
  3: 'https://api.studio.thegraph.com/query/81903/uno-re-ssip-usdc-eth/version/latest',
  4: 'https://api.studio.thegraph.com/query/81903/uno-re-ssip-eth-subgraph/version/latest',
  5: 'https://api.studio.thegraph.com/query/81903/uno-re-ssip-uno-eth/version/latest',
  6: 'https://api.studio.thegraph.com/query/81903/uno-re-ssrp-uno-eth/version/latest',
  7: 'https://rollux.graph.pegasys.fi/subgraphs/name/unore-ssip-wsys-rollux',
  8: 'https://rollux.graph.pegasys.fi/subgraphs/name/unore-ssip-uno-rollux',
}

export const GRAPH_ENDPOINTS_TESTNET = {
  0: 'https://api.studio.thegraph.com/query/81903/uno-re-ssip-usdc-bsc/version/latest',
  1: 'https://api.studio.thegraph.com/query/81903/uno-re-ssip-uno-bsc/version/latest',
  2: 'https://api.studio.thegraph.com/query/78945/unore-ssip-usdt-sepolia/version/latest',
  3: 'https://api.studio.thegraph.com/query/78945/unore-ssip-usdc-sepolia/version/latest',
  4: 'https://api.studio.thegraph.com/query/78945/unore-ssip-uno-sepolia/version/latest',
  5: 'https://api.thegraph.com/subgraphs/name/uno-re/core-v2-contracts',
  6: 'https://api.thegraph.com/subgraphs/name/uno-re/ssrp-goerli',
  7: 'https://rollux.graph.pegasys.fi/subgraphs/name/unore-ssip-wsys-rollux',
  8: 'https://rollux.graph.pegasys.fi/subgraphs/name/unore-ssip-uno-rollux',
}
export const OOV3GRAPH_URL = 'https://api.studio.thegraph.com/query/1057/sepolia-optimistic-oracle-v3/version/latest'
export const API_BASE_URL_COVER =
  Number(process.env.REACT_APP_CHAIN_ID) === 1 ? 'https://admin.unore.io/api/' : 'https://staging-admin.unore.io/api/'
//const admin = 'https://staging-admin.unore.io/api/'
//https://admin.unore.io/api

export const EndPoints = {
  HEALTHY: `${API_BASE_URL_COVER}`,
  USER_LOGIN: `${API_BASE_URL_COVER}signin`,
  USER_REGISTER: `${API_BASE_URL_COVER}signup`,
  USER_WALLET: `${API_BASE_URL_COVER}wallet-transactions`,
  COVER_USD_PRICE: `${API_BASE_URL_COVER}getcoverusdprice`,
  USDCAMT_ONE_DOLLAR: `${API_BASE_URL_COVER}usdc-price`,
  FUNCTION_SIG: `${API_BASE_URL_COVER}get-signature`,
  FUNCTION_SIG_RINKEBY: `${API_BASE_URL_COVER}get-signature-rinkeby`,
  UNLOCKED_CONTRACT: `${API_BASE_URL_COVER}unlocked-smart-contracts`,
  ERC20TOEKN_LIST: `${API_BASE_URL_COVER}erc20`,
  ERC20TOKEN_PRICE: `${API_BASE_URL_COVER}erc20Price`,
  ERC20META: `${API_BASE_URL_COVER}erc20/metadata`,
  PROTOCOL_SEARCH: `${API_BASE_URL_COVER}protocols`,
  VERIFIED_TOKENS: `${API_BASE_URL_COVER}erc20/verified`,
  UNLOCKED_CONTRACT_PARAMS: `${API_BASE_URL_COVER}unlocked-smart-contract-params`,
  CREATE_CLAIM_REQUEST: `${API_BASE_URL_COVER}create-claim-request`,
  GET_CLAIM_REQUESTS: `${API_BASE_URL_COVER}claims`,
  GET_CLAIM_BY_ID: `${API_BASE_URL_COVER}claim`,
  GET_ACCOUNT_SIGNATURE: `${API_BASE_URL_COVER}get-account-signature`,
  ADD_ACCOUNT_SIGNATURE: `${API_BASE_URL_COVER}add-signature`,
  GET_CLAIMS_BY_POLICY: `${API_BASE_URL_COVER}get-claims-by-policy`,
  GET_OLDV2_USER_STATUS: `${API_BASE_URL_COVER}get-oldv2-user-status`,
  ADD_OLDV2_USER: `${API_BASE_URL_COVER}add-oldv2-user`,
  CHECK_IS_ALLOWED: `${API_BASE_URL_COVER}allowlist`,
  UPLOAD_DOCS: `${API_BASE_URL_COVER}upload-document`,
}
